::-webkit-scrollbar {
    display: none;
}
body,html{
    overflow-x: hidden;
    /* overflow-y: hidden; */
    background: url(/public/bg1.webp) no-repeat center center fixed;
    
}





